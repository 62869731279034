import undoAble, { excludeAction, ActionTypes } from 'redux-undo'
import {
  DESIGN_STATUS,
  PORTRAIT,
  STANDARD,
  SPACING,
} from 'constants/EnterpriseKeyboardBuilderConstant'
import { getLayoutHeight } from 'views/app-views/enterpriseKeyboardBuilder/utils/keyboardSizing'
import {
  NEXT_STEP,
  PREVIOUS_STEP,
  RESET,
  SET_LAYOUT_VALUES,
  SET_LAYOUT_CURRENT_INDEX,
  ADD_LAYOUT,
  REMOVE_LAYOUT,
  SET_KEYBOARD_VALUES,
  SET_STATUS_BAR,
  SET_SYSTEM_BAR,
  SET_KEY_SELECTED,
  SET_ID,
  SET_KEY_VALUES,
  SET_GENERAL_KEY,
  RESET_STEP,
  SET_SCREEN_SIZE,
  SET_KEY_BUTTON,
  SET_ACTION_KEY_BUTTON,
  SET_KEYBOARD,
  SET_LAYOUT_PROPERTIES,
  SET_LAYOUTS,
  SET_KEY_ACTIONS_VALUES,
  SET_IS_EDITOR_MODE,
  UPDATE_LAYOUT_PROPERTIES,
  ADD_ROW_KEYBOARD,
  SET_ROW_WIDTH,
  SET_ROW_WIDTH_BALANCE,
  APPLY_TO_ALL_KEYS,
  APPLY_TO_ALL_LAYOUTS,
  SET_TRANSPARENCY,
  DELETE_ROW_KEY_BUTTON,
  SET_BACKGROUND_COLOR_LAYOUT_PROPERTIES,
  SET_POSITION_LAYOUT_PROPERTIES,
  APPLY_TO_ALL_KEYS_IN_LAYOUTS,
  SET_PREVIEWKEY_PROPERTIES,
  SET_MARGIN_SPACING_LAYOUT_PROPERTIES,
  SET_HEIGHT_MANUAL_LAYOUT_PROPERTIES,
  SET_WIDTH_MANUAL_LAYOUT_PROPERTIES,
  SET_HEIGHT_STANDARD_LAYOUT_PROPERTIES,
  SET_WIDTH_STANDARD_LAYOUT_PROPERTIES,
  SET_STYLING_KEY_VALUES,
  SET_BACKGROUND_COLOR_KEY_VALUE,
  SET_HAPTIC_FEEDBACK_KEY_VALUES,
  SET_ACTIONS_KEY_VALUES,
  SET_CONTENT_KEY_VALUES,
  SET_DEPLOY_KEYBOARD_VALUES
} from 'redux/constants/KeyboardBuilder'
import { generateKeyButtons, getLayoutId } from 'utils/enterpriseKeyboardBuild'
import { screenSize } from 'views/app-views/enterpriseKeyboardBuilder/utils/screenSize'
import { cloneDeep, merge } from 'lodash'
import { updateListReferences } from 'views/app-views/enterpriseKeyboardBuilder/utils/updateListReferences'

const LIMIT_UNDO_REDO = 11
const ROWS = 2

const initState = () => ({
  isEditorMode: false,
  wizardStep: 0,
  keySelected: null,
  layoutCurrentIndex: 0,
  keyboard: {
    devicePreview: {
      statusBar: true,
      systemBar: true,
    },
    screen: screenSize.mobile,
    generalKey: {
      backgroundColor: '#0077a0',
      backgroundColorPressed: '#0077a0',
      hapticFeedback: false,
      hapticFeedbackDuration: 1,
      primary: {
        style: 'normal',
        color: '#FFFFFF',
        size: 16,
        press: { type: '', value: '' },
        up: { type: '', value: '' },
      },
      secondary: {
        style: 'normal',
        color: '#FFFFFF',
        size: 8,
        type: '',
        longPress: { type: '', value: '' },
        down: { type: '', value: '' },
      },
    },
    generalLayout: {
      backgroundColor: '#005a78',
      preview: false,
    },
    info: {
      category: 1,
      description: '',
      deviceType: 'mobile',
      name: undefined,
      profileType: 2,
      status: DESIGN_STATUS.DRAFT,
      tags: ['warehouse'],
      userId: '',
    },
    zoomLevel: 1,
    layouts: [
      {
        properties: {
          id: getLayoutId(),
          name: '',
          rows: ROWS,
          columns: 2,
          width: screenSize.mobile?.width,
          height: getLayoutHeight(
            ROWS,
            screenSize.mobile?.height,
            1,
            SPACING.standard.keyGap,
            SPACING.standard.layoutGap
          ),
          backgroundColor: '#005a78',
          transparency: 0, //default 0 no-transparency
          isHeightResizable: false,
          isWidthResizable: false,
          keyGap: 4,
          layoutGap: 4,
          spacing: STANDARD,
          orientation: PORTRAIT,
          status: 'anchored',
          direction: 'bottom-middle',
          top: 0,
          left: 0,
          clickable: false,
          preview: false,
        },
        buttons: generateKeyButtons(2, 2, screenSize.mobile?.width),
        generalKey: {
          backgroundColor: '#0077a0',
          backgroundColorPressed: '#0077a0',
          height: 0,
          hapticFeedback: false,
          hapticFeedbackDuration: 1,
          transparency: 0,
          preview: true,
          primary: {
            style: 'normal',
            color: '#FFFFFF',
            size: 16,
          },
          secondary: {
            size: 8,
          },
        },
      },
    ],
  },
})

const enterpriseKeyboardBuilder = (state = initState(), action) => {
  switch (action.type) {
    case RESET: {
      return initState()
    }

    case SET_KEY_ACTIONS_VALUES: {
      const { keyButton, keySelected } = action.payload
      const newState = cloneDeep(state)
      const { row, col } = keySelected
      const column = newState.keyboard.layouts[state.layoutCurrentIndex].buttons[row].columns
      column[col] = { ...column[col], ...keyButton }
      return newState
    }

    case SET_DEPLOY_KEYBOARD_VALUES: {
      return {
        ...state,
        deployKeyboard: action.payload,
      }
    }

    case SET_KEYBOARD:
      return cloneDeep({
        ...state,
        keyboard: action.payload,
      })

    case SET_ACTION_KEY_BUTTON:
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          actions: {
            ...state.keyboard.actions,
            ...action.payload,
          },
        },
      })

    case SET_IS_EDITOR_MODE:
      return {
        ...state,
        isEditorMode: action.payload,
      }

    case SET_KEY_BUTTON:
      const newState = cloneDeep(state)
      const { rowKeySelected, columns } = action.payload
      newState.keyboard.layouts[state.layoutCurrentIndex].buttons[rowKeySelected].columns = columns
      return newState

    case SET_SCREEN_SIZE:
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          screen: {
            ...state.keyboard.screen,
            ...action.payload,
          },
        },
      })

    case SET_KEY_SELECTED:
      return {
        ...state,
        keySelected: action.payload,
      }

    case SET_GENERAL_KEY:
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          generalKey: {
            ...state.keyboard.generalKey,
            ...action.payload,
          },
        },
      })

    case SET_STATUS_BAR:
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          devicePreview: {
            ...state.keyboard.devicePreview,
            statusBar: action.payload,
          },
        },
      })

    case SET_SYSTEM_BAR:
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          devicePreview: {
            ...state.keyboard.devicePreview,
            systemBar: action.payload,
          },
        },
      })

    case SET_KEYBOARD_VALUES: {
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          info: {
            ...state.keyboard.info,
            ...action.payload,
          },
        },
      })
    }

    case NEXT_STEP: {
      return {
        ...state,
        wizardStep: state.wizardStep + 1,
      }
    }

    case PREVIOUS_STEP: {
      return {
        ...state,
        wizardStep: state.wizardStep - 1,
      }
    }

    case SET_ID: {
      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          id: action.payload.id,
        },
      }
    }

    case SET_LAYOUTS: {
      return cloneDeep({
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: action.payload,
        },
      })
    }

    case ADD_ROW_KEYBOARD: {
      const { buttons, properties } = action.payload
      const layouts = cloneDeep(state.keyboard.layouts)
      layouts[state.layoutCurrentIndex].buttons = buttons
      layouts[state.layoutCurrentIndex].properties = {
        ...layouts[state.layoutCurrentIndex].properties,
        ...properties,
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: layouts,
        },
      }
    }

    case DELETE_ROW_KEY_BUTTON: {
      const payload = action.payload
      const layout = state.keyboard.layouts[state.layoutCurrentIndex]
      const newLayout = cloneDeep({
        ...layout,
        ...payload,
        properties: {
          ...layout.properties,
          ...payload.properties,
        },
        generalKey: {
          ...layout.generalKey,
          ...payload.generalKey,
        },
      })

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: updateListReferences(state.keyboard.layouts, newLayout, state.layoutCurrentIndex),
        },
      }
    }

    case SET_LAYOUT_VALUES: {
      const payload = cloneDeep(action.payload)
      const newState = cloneDeep(state)
      const layout = newState.keyboard.layouts[state.layoutCurrentIndex]
      const newLayout = {
        ...layout,
        ...payload,
        properties: {
          ...layout.properties,
          ...payload.properties,
        },
        generalKey: {
          ...layout.generalKey,
          ...payload.generalKey,
        },
      }
      newState.keyboard.layouts[state.layoutCurrentIndex] = newLayout
      return newState
    }

    case SET_HEIGHT_STANDARD_LAYOUT_PROPERTIES:
    case SET_WIDTH_STANDARD_LAYOUT_PROPERTIES:
    case SET_HEIGHT_MANUAL_LAYOUT_PROPERTIES:
    case SET_WIDTH_MANUAL_LAYOUT_PROPERTIES:
    case SET_MARGIN_SPACING_LAYOUT_PROPERTIES:
    case SET_POSITION_LAYOUT_PROPERTIES:
    case SET_LAYOUT_PROPERTIES: {
      const layouts = state.keyboard.layouts
      const layoutCopy = cloneDeep(layouts[state.layoutCurrentIndex])
      const layout = {
        ...layoutCopy,
        properties: {
          ...layoutCopy.properties,
          ...action.payload,
        },
        generalKey: {
          ...layoutCopy.generalKey,
          ...action.payload,
        },
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: updateListReferences(layouts, layout, state.layoutCurrentIndex),
        },
      }
    }

    case SET_TRANSPARENCY: {
      const layout = state.keyboard.layouts[state.layoutCurrentIndex]
      const newLayout = {
        ...layout,
        properties: {
          ...layout.properties,
          ...action.payload,
        },
        generalKey: {
          ...layout.generalKey,
          ...action.payload,
        },
        buttons: layout.buttons.map((button) => ({
          ...button,
          columns: button.columns.map((item) => merge(item, action.payload)),
        })),
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: updateListReferences(
            state.keyboard.layouts,
            newLayout,
            state.layoutCurrentIndex
          ),
        },
      }
    }

    case SET_BACKGROUND_COLOR_LAYOUT_PROPERTIES: {
      const layouts = state.keyboard.layouts
      const layoutCopy = cloneDeep(layouts[state.layoutCurrentIndex])
      const layout = {
        ...layoutCopy,
        properties: {
          ...layoutCopy.properties,
          ...action.payload,
        },
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: updateListReferences(layouts, layout, state.layoutCurrentIndex),
        },
      }
    }

    case SET_PREVIEWKEY_PROPERTIES: {
      const layouts = state.keyboard.layouts
      const layoutCopy = cloneDeep(layouts[state.layoutCurrentIndex])

      layoutCopy.buttons = layoutCopy.buttons.map((button) => {
        return {
          ...button,
          columns: button.columns.map((item) => merge(item, action.payload)),
        }
      })

      const layout = {
        ...layoutCopy,
        generalKey: {
          ...layoutCopy.generalKey,
          ...action.payload,
        },
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: updateListReferences(layouts, layout, state.layoutCurrentIndex),
        },
      }
    }

    case UPDATE_LAYOUT_PROPERTIES: {
      const layouts = cloneDeep(state.keyboard.layouts)

      layouts[action.layoutIndex] = {
        ...layouts[action.layoutIndex],
        properties: {
          ...layouts[action.layoutIndex]?.properties,
          ...action.payload,
        },
      }

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts,
        },
      }
    }

    case SET_ACTIONS_KEY_VALUES:
    case SET_CONTENT_KEY_VALUES:
    case SET_STYLING_KEY_VALUES:  //Font size, Font color, Font style
    case SET_BACKGROUND_COLOR_KEY_VALUE: //Background color, Background color pressed
    case SET_HAPTIC_FEEDBACK_KEY_VALUES: //Haptic feedback, Haptic feedback duration
    case SET_KEY_VALUES: {
      const newState = cloneDeep(state)
      const { row, col } = state.keySelected
      const column = newState.keyboard.layouts[state.layoutCurrentIndex].buttons[row].columns
      column[col] = { ...column[col], ...action.payload }
      return newState
    }

    case SET_LAYOUT_CURRENT_INDEX: {
      return cloneDeep({
        ...state,
        layoutCurrentIndex: action.payload.index,
      })
    }

    case SET_ROW_WIDTH_BALANCE:
    case SET_ROW_WIDTH: {
      const layouts = cloneDeep(state.keyboard.layouts)
      const layout = layouts[action.payload.layoutIndex]
      const row = layout.buttons[action.payload.rowIndex]

      row.columns = row.columns.map((button, i) => {
        button.width = action.payload.row[i]
        return button
      })

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts,
        },
      }
    }

    case ADD_LAYOUT: {
      const newState = cloneDeep(state)
      newState.keyboard.layouts = [...newState.keyboard.layouts, action.payload]
      newState.layoutCurrentIndex = newState.keyboard.layouts.length - 1
      newState.keySelected = { row: 0, col: 0 }
      return newState
    }

    case REMOVE_LAYOUT: {
      const newState = cloneDeep(state)
      const { index } = action.payload
      const layoutCurrentIndex = state.layoutCurrentIndex === index ? 0 : state.layoutCurrentIndex
      const newStateWithCurrentLayout = { ...newState, layoutCurrentIndex }
      newStateWithCurrentLayout.keyboard.layouts =
        newStateWithCurrentLayout.keyboard.layouts.filter((_, idx) => idx !== index)
      return newStateWithCurrentLayout
    }

    case APPLY_TO_ALL_KEYS: {
      const layouts = cloneDeep(state.keyboard.layouts)

      layouts[state.layoutCurrentIndex].buttons = layouts[state.layoutCurrentIndex].buttons.map(
        (button) => {
          return {
            ...button,
            columns: button.columns.map((item) => merge(item, action.payload)),
          }
        }
      )

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts,
        },
      }
    }

    case APPLY_TO_ALL_KEYS_IN_LAYOUTS: {
      const layouts = cloneDeep(state.keyboard.layouts)
      const newLayouts = layouts.map((layout) => {
        if (layout.properties.name.includes('@ekb-')) return layout
        return {
          ...layout,
          buttons: layout.buttons.map((button) => {
            return {
              ...button,
              columns: button.columns.map((item) => merge(item, action.payload)),
            }
          })
        }
      })

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: newLayouts,
        },
      }
    }

    case APPLY_TO_ALL_LAYOUTS: {
      const layouts = cloneDeep(state.keyboard.layouts)
      const newLayouts = layouts.map((layout) => {
        if (layout.properties.name.includes('@ekb-')) return layout
        return {
          ...layout,
          properties: {
            ...layout.properties,
            ...action.payload,
          },
        }
      })

      return {
        ...state,
        keyboard: {
          ...state.keyboard,
          layouts: newLayouts,
        },
      }
    }

    case RESET_STEP: {
      return {
        ...state,
        wizardStep: 0,
      }
    }

    default:
      return state
  }
}

const RemoveUndoAble = [
  RESET_STEP,
  SET_ID,
  PREVIOUS_STEP,
  NEXT_STEP,
  SET_IS_EDITOR_MODE,
  SET_KEY_SELECTED,
  SET_ROW_WIDTH_BALANCE,
  SET_LAYOUT_CURRENT_INDEX
]

const undoAbleEnterpriseKeyboardBuilder = undoAble(enterpriseKeyboardBuilder, {
  limit: LIMIT_UNDO_REDO,
  syncFilter: true,
  clearHistoryType: ActionTypes.CLEAR_HISTORY,
  filter: excludeAction(RemoveUndoAble),
})
export default undoAbleEnterpriseKeyboardBuilder

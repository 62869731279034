// NEW CI&T
export default {
  apiKey: "AIzaSyBO_qJR3nCcZd3FmrrPdeMvS4ICSYSHGbs",
  authDomain: "cto-si-modelregistry-p.firebaseapp.com",
  projectId: "cto-si-modelregistry-p",
  storageBucket: "cto-si-modelregistry-p.appspot.com",
  messagingSenderId: "1030748491037",
  appId: "1:1030748491037:web:f03916ca2f6f703e99e5f4",
  measurementId: "G-5941V6QC9L",
  databaseURL: "https://cto-si-modelregistry-p.firebaseio.com"
}

import { all, takeEvery, put, fork, call } from 'redux-saga/effects'
import {
  AUTH_TOKEN,
  TENANT_ID,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_CUSTOM_TOKEN,
  SIGNIN_WITH_FACEBOOK,
  FIREBASE_USER_TENANT_ID,
  HIDE_POPUP_INITIALSETUP,
  HIDE_POPUP_VIEWBARCODE,
  HIDE_POPUP_NEWSETTING,
  HIDE_POPUP_APPLYSETTING,
  EMAIL_ID,
  ROLE,
  TENANT_CREATED_TIME,
  FIREBASE_USERNAME,
  LOGIN_TYPE,
  SHOW_POPUP,
} from '../constants/Auth'
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from '../actions/Auth'
import FirebaseService from 'services/FirebaseService'
import { GOOGLE_DRIVE_TOKEN } from 'constants/Constant'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload
    console.log('Trying to sign in with email password')
    console.log(payload)
    //const testtoken = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTYwNTI5OTc0NSwiZXhwIjoxNjA1MzAzMzQ1LCJpc3MiOiJlbWMta3ItZGV2LWRAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwic3ViIjoiZW1jLWtyLWRldi1kQGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsInVpZCI6ImNmaDQ2MyIsImNsYWltcyI6eyJ1c2VyIjoiYWRtaW4iLCJlbWFpbCI6ImNmaDQ2MyJ9fQ.TJ1HCJdKd-jXlgB51Wa1ubVIYzKTEQ2tqWNHqCzO51ayLGss806y252VyZsn5VdaVjIsodPb2ucUB1_kpGAF5wBmy07PRf8wRPYE2pdZg4B0YAo4SuTpwrE-W28DDPCAud12VLdN_yiY0k4h87n9Y_PSLod2l7aG74LUuvy5RFYz_vnbDkU0PEKEzoRDAsOv48SV-jXdoGKfWTFNEKo9Egb2TYEDdKdAb1uRQHzfJp3QqtaOIV9DBl6Zh_FYExZ5cvwYcI18uk6315ZzuCgaBY8kavkRX6hTUsisduP48lIwUeE628k8XBfnpleLNGG54D-fN_9CSpKUpNA8KNelnw";
    try {
      //const user = yield call(FirebaseService.signInCustomToken, testtoken);
      const user = yield call(FirebaseService.signInEmailRequest, email, password)
      console.log(user)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid)
        console.log(user.user.uid)
        yield put(authenticated(user.user.uid))
      }
    } catch (err) {
      yield put(showAuthMessage(err))
    }
  })
}

/* export function* signInWithCustomToken() {
  yield takeEvery(SIGNIN_WITH_CUSTOM_TOKEN, function* ({payload}) {
      console.log("Trying to sign in with custom token: ", payload);
      const {token} = payload;
      try {
        const user = yield call(FirebaseService.signInCustomToken, token);
        console.log("User",user);
        if (user.message) {
          yield put(showAuthMessage(user.message));
        } else {
          localStorage.setItem(AUTH_TOKEN, user.user.uid);
          localStorage.setItem(TENANT_ID, user.user.uid);
          localStorage.setItem(EMAIL_ID, payload.email);
          localStorage.setItem(ROLE, payload.role);
          yield put(authenticated(user.user.uid));
        }
      } catch (err) {
        yield put(showAuthMessage(err));
      }
    });
  } */

export function* signOut() {
  yield takeEvery(SIGNOUT, function* ({ payload }) {
    try {
      console.log("payload: ", payload)
      localStorage.setItem(LOGIN_TYPE, payload)
      const signOutUser = yield call(FirebaseService.signOutRequest)
      if (signOutUser === undefined) {
        //localStorage.removeItem(AUTH_TOKEN);
        //localStorage.removeItem(FIREBASE_USER_TENANT_ID)
        localStorage.removeItem(HIDE_POPUP_INITIALSETUP)
        localStorage.removeItem(HIDE_POPUP_VIEWBARCODE)
        localStorage.removeItem(HIDE_POPUP_NEWSETTING)
        localStorage.removeItem(HIDE_POPUP_APPLYSETTING)
        //localStorage.removeItem(EMAIL_ID)
        //localStorage.removeItem(ROLE)
        localStorage.removeItem(TENANT_ID)
        localStorage.removeItem(TENANT_CREATED_TIME)
        //localStorage.removeItem(FIREBASE_USERNAME)
        localStorage.removeItem(GOOGLE_DRIVE_TOKEN.ACCESSTOKEN)
        localStorage.removeItem(GOOGLE_DRIVE_TOKEN.ACCESSTOKEN_EXPIRY)
        yield put(signOutSuccess(signOutUser))
        localStorage.removeItem(LOGIN_TYPE)
        localStorage.removeItem(SHOW_POPUP)
      } else {
        yield put(showAuthMessage(signOutUser.message))
        localStorage.removeItem(LOGIN_TYPE)
        localStorage.removeItem(SHOW_POPUP)
      }
    } catch (err) {
      yield put(showAuthMessage(err))
      localStorage.removeItem(LOGIN_TYPE)
      localStorage.removeItem(SHOW_POPUP)
    }
  })
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload
    try {
      const user = yield call(FirebaseService.signUpEmailRequest, email, password)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid)
        yield put(signUpSuccess(user.user.uid))
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid)
        yield put(signInWithGoogleAuthenticated(user.user.uid))
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest)
      if (user.message) {
        yield put(showAuthMessage(user.message))
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid)
        yield put(signInWithFacebookAuthenticated(user.user.uid))
      }
    } catch (error) {
      yield put(showAuthMessage(error))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    //fork(signInWithCustomToken),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
  ])
}

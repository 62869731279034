import React, { useEffect, useState } from 'react'
import { auth,onAuthStateChanged } from './FirebaseAuth'
import { getDocsFromAuthentication } from '../firebase/cloudFunction/index'
import {
  TENANT_PERMISSION_TYPE,
  ADMIN_PERMISSION_TYPE,
  FIREBASE_USERNAME,
  TENANT_CREATED_TIME,
  LOGIN_TYPE,
} from '../redux/constants/Auth'
import { Spin } from 'antd'
import './Auth.css'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  useEffect(() => {
    onAuthStateChanged(auth, async(user) => {
      if (user) {
        //console.log('User - ' + JSON.stringify(user))
        await setCustomClaims(user)
      }

      setCurrentUser(user)
      setPending(false)
    })
  }, [])

  async function setCustomClaims(user) {
    let idTokenResult = await user.getIdTokenResult()
    // console.log('idTokenResult - ' + JSON.stringify(idTokenResult))
    user.claims = idTokenResult.claims
    user.claims['role'] =
      user.claims.role === TENANT_PERMISSION_TYPE ? ADMIN_PERMISSION_TYPE : TENANT_PERMISSION_TYPE

    let loginType = user.claims.loginType ? user.claims.loginType : '1'
    if(localStorage.getItem(LOGIN_TYPE)) {
      loginType = localStorage.getItem(LOGIN_TYPE)
    }
    
    console.log('LOGIN TYPE VALUS IS ',loginType)
    
    if (user.claims.role === ADMIN_PERMISSION_TYPE && loginType == '1') {
      let doc = await getDocsFromAuthentication(user.uid)
      user.claims[FIREBASE_USERNAME] = doc.data.userName
      user.claims['enrollmentTokenAvailable'] = doc.data.enrollmentTokenAvailable
      user.claims['user_id'] = doc.data.adminTenantId
      user.claims[TENANT_CREATED_TIME] = doc.data.createdDateTime
      user.claims['loginType'] = '1'
      user.claims['friendlyId'] = doc.data.friendlyCompanyId
      //console.log('setCustomClaims - ' + JSON.stringify(user.claims))
    } 
    else if (user.claims.role === ADMIN_PERMISSION_TYPE && loginType == '2') {
      user.claims['user_id'] = user.claims.identity
    }
  }

  if (pending) {
    return (
      <>
        <Spin size="large" tip="Loading" />
      </>
    )
  }

  return <AuthContext.Provider value={{ currentUser }}>{children}</AuthContext.Provider>
}

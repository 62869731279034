import { getFirestore, collection, query, getDocs, getDoc, doc, setDoc, collectionGroup, deleteDoc, onSnapshot } from "firebase/firestore";
import { db } from 'auth/FirebaseAuth'

//get single document
function getDocument(colPath, documentId){
    let query = doc(db, colPath, documentId)
    console.log('query', query)
    return getDoc(query)
}

//get multiple documents

function getMultipleDocument(collectionPath, conditions = []){
    let qry = collection(db, collectionPath)
    return getDocs(query(qry,...conditions))
}

//get document using collection group 

function getDocumentCollectionGroup(collectionName, conditions=[]){
    let qry = collectionGroup(db, collectionName)
    return getDocs(query(qry,...conditions))
}

//update document
function setDocument(collectionPath, documentId, data, isMerge){
    const documentRef = doc(getFirestore(), collectionPath, documentId);
    if(isMerge){
        return setDoc(documentRef, data, { merge: true });
    }else{
        return setDoc(documentRef, data );
    }
}

//delete document
function deleteDocument(collectionPath, documentId){
 return deleteDoc(doc(db, collectionPath, documentId))
}


export{
    getDocument,
    setDocument,
    getMultipleDocument,
    getDocumentCollectionGroup,
    deleteDocument
}
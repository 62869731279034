import React, { lazy, Suspense, useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthContext } from 'auth/AuthProvider'
import { getTenantId, getRole } from 'auth/FirebaseAuth'
import { TENANT_PERMISSION_TYPE } from 'redux/constants/Auth'
import Loading from 'components/shared-components/Loading'
import './styles.css'

export const UserViews = ({ match }) => {
  const context = useContext(AuthContext)
  const [role] = useState(getRole(context?.currentUser))

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${match.url}/myProfile`} component={lazy(() => import(`./myProfile`))} />
        {role === TENANT_PERMISSION_TYPE ? (
          <>
            <Route
              path={`${match.url}/myServices`}
              component={lazy(() => import(`./myServices`))}
            />
            <Route
              path={`${match.url}/userManagement`}
              component={lazy(() => import(`./userManagement`))}
            />
          </>
        ) : null}
        <Redirect from={`${match.url}`} to={`${match.url}/myProfile`} />
      </Switch>
    </Suspense>
  )
}

export default UserViews
